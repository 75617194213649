
import CommonMixin from "@/helpers/mixins/CommonMixin";
import { Vue, mixins, Options } from "vue-class-component";
import { BASE_BSC_SCAN_URL } from "@/config";
import { getPresaleAddress } from "@/utils/addressHelpers";
import { watchEffect } from "vue";
import { SerializedPresale } from "@/config/constants/types";
import { BigNumber } from "bignumber.js";
import {
  getDecimalAmount,
  getFullDisplayBalance,
  formatNumber,
  getBalanceNumber
} from "@/utils/formatBalance";
import useCatchTxError from "@/helpers/useCatchTxError";
import { TxResponse } from "@/helpers/useCatchTxError";
import usePurchaseTokens from "@/helpers/presale/usePurchaseTokens";
import useClaimToken from "@/helpers/presale/useClaimToken";
import { updateUserBalance, updateUserPresaleRecord } from "@/helpers/presale";

class Props {
  presale: SerializedPresale;
  currentBalance: BigNumber;
}
@Options({
  components: {},
})
export default class PreSaleCard extends mixins(Vue.with(Props), CommonMixin) {
  BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URL;
  userDataLoaded: boolean = false;
  BigNumber = BigNumber;
  fullDecimalBuyAmount: BigNumber = new BigNumber(0);
  buyAmount = "";
  userNotEnoughToken: boolean = false;
  minBuyAmountRequired: boolean = false;
  pendingTx: boolean = false;
  pendingClaimTx: boolean = false;

  formatNumber(number: number, minPrecision = 2, maxPrecision = 2) {
    return formatNumber(number, minPrecision, maxPrecision);
  }

  getFullDisplayBalance(
    balance: BigNumber,
    decimals = 18,
    displayDecimals?: number
  ) {
    return getFullDisplayBalance(balance, decimals, displayDecimals);
  }

  getCalculatedBuyLimit() {
    return this.currentBalance;
  }

  getBalanceNumber(amount: BigNumber, decimals = 18) {
    return getBalanceNumber(amount, decimals);
  }

  get contractAddress() {
    return getPresaleAddress();
  }

  handleInputChange(e) {
    const input = e.value;
    this.buyAmount = input;
  }

  handleChangePercent(sliderPercent: number) {
    if (sliderPercent > 0) {
      const percentageOfStakingMax = this.getCalculatedBuyLimit()
        .dividedBy(100)
        .multipliedBy(sliderPercent);
      const amountToBuy = getFullDisplayBalance(
        percentageOfStakingMax,
        18,
        18
      );
      this.buyAmount = amountToBuy;
    } else {
      this.buyAmount = "0";
    }
  }

  async handleConfirmClick() {
    if (
      !this.buyAmount ||
      parseFloat(this.buyAmount) === 0 ||
      this.userNotEnoughToken
    )
      return;
    const { onPurchase } = usePurchaseTokens();
    let tx: TxResponse = null;
    try {
      this.pendingTx = true;
      tx = await onPurchase(this.buyAmount);
      const receipt = await tx.wait();
      if (receipt?.status) {
        this.$store.commit("useToast", {
          severity: "success",
          summary: "Purchased",
          detail: `Your purchase is successful!`,
        });
        const address = this.$store.state.web3.user.address;

        updateUserBalance(address);
        updateUserPresaleRecord(address);

        this.buyAmount = "";
      }
      this.$store.commit("useTxToast", { txHash: tx.hash });
    } catch (err) {
      console.log(err)
      useCatchTxError(err, tx);
    } finally {
      this.pendingTx = false;
    }
  }

  async handleClaimToken() {
    const { onClaim } = useClaimToken();
    let tx: TxResponse = null;
    try {
      this.pendingClaimTx = true;
      tx = await onClaim();
      const receipt = await tx.wait();
      if (receipt?.status) {
        this.$store.commit("useToast", {
          severity: "success",
          summary: "Claimed",
          detail: `Claimed tokens have been sent to your wallet!`,
        });
        const address = this.$store.state.web3.user.address;

        updateUserPresaleRecord(address);
      }
      this.$store.commit("useTxToast", { txHash: tx.hash });
    } catch (err) {
      console.log(err)
      useCatchTxError(err, tx);
    } finally {
      this.pendingClaimTx = false;
    }
  }

  mounted() {
    watchEffect(() => {

      this.fullDecimalBuyAmount = getDecimalAmount(
        new BigNumber(this.buyAmount),
        18
      );

      this.userNotEnoughToken = new BigNumber(this.currentBalance).lt(
        this.fullDecimalBuyAmount
      );
      this.minBuyAmountRequired = this.getBalanceNumber(this.fullDecimalBuyAmount, 18) < this.getBalanceNumber(this.presale.minAmount, 0);
    });
  }
}
