import { DEFAULT_GAS_LIMIT } from '@/config'
import getGasPrice from '@/utils/getGasPrice'
import { usePresale, claimTokensGasEstimateLimit } from '../useContract'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const claim = async (presaleContract) => {
  const gasPrice = getGasPrice()
  const gasLimit = await claimTokensGasEstimateLimit();

  return presaleContract.claimTokens({
    ...options,
    gasPrice,
    gasLimit,
  })
}

const useClaimToken = () => {
  const presaleContract = usePresale()

  const handleClaim = async () => {
    return claim(presaleContract)
  }

  return { onClaim: handleClaim }
}

export default useClaimToken
