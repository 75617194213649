import {
  fetchBalance,
  fetchUserDetails,
  getUserPresaleData,
} from "@/store/modules/presale/fetchPresaleData";
import store from "@/store";
import { watchEffect } from "vue";

export const presaleWithUserDataLoadingSelector = () => {
  const presaleData = store.state.presale.data;
  const userDataLoaded = store.state.presale.userDataLoaded;

  return { data: presaleData, userDataLoaded };
};

export const usePresale = () => {
  const presaleWithUserDataLoading = presaleWithUserDataLoadingSelector();
  const { data, userDataLoaded } = presaleWithUserDataLoading;

  return { data, userDataLoaded };
};

export const usePresalePageFetch = () => {
  watchEffect(() => {
    const { address } = store.state.web3.user;
    if (address) {
      store.dispatch("fetchPresaleUserDataAsync", address);
    }
  });
};

export const updateUserPresaleDetails = async (account: string) => {
  const userPresaleDetail = await fetchUserDetails(account);
  const fields = ["amountClaim"];

  fields.map((field) => {
    store.commit("updatePresaleUserData", {
      field: field,
      value: userPresaleDetail[field],
    });
  });
};

export const updateUserPresaleRecord = async (account: string) => {
  const newUserData = await getUserPresaleData(account);

  store.commit("setPresaleUserData", newUserData);
};

export const updateUserBalance = async (account: string) => {
  const balance = await fetchBalance(account);
  store.commit("updatePresaleUserData", { field: "balance", value: balance });
};
