import { DEFAULT_GAS_LIMIT } from '@/config'
import { BIG_TEN } from '@/utils/bigNumber'
import getGasPrice from '@/utils/getGasPrice'
import BigNumber from 'bignumber.js'
import { usePresale, estimateGasLimit } from '../useContract'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const purchase = async (presaleContract, amount) => {
  const decimals = 18;
  const gasPrice = getGasPrice()
  const depositAmount = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(10)
  const gasLimit = await estimateGasLimit(depositAmount);

  return presaleContract.purchaseTokens({
    ...options,
    gasPrice,
    gasLimit,
    value: depositAmount
  })
}

const usePurchaseTokens = () => {
  const presaleContract = usePresale()

  const handlePurchase = async (amount: string) => {
    return purchase(presaleContract, amount)
  }

  return { onPurchase: handlePurchase }
}

export default usePurchaseTokens
