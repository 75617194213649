
import CommonMixin from "@/helpers/mixins/CommonMixin";
import { Options } from "vue-class-component";
import { usePresalePageFetch, usePresale } from "@/helpers/presale";
import { watchEffect } from "vue";
import { SerializedPresale } from "@/config/constants/types";
import { BigNumber } from "bignumber.js";
import PresaleCard from "@/components/PresaleCard.vue";

@Options({
  components: {PresaleCard},
})
export default class PreSale extends CommonMixin {
  userDataLoaded: boolean = false;
  presale: SerializedPresale = null;
  currentBalance: BigNumber = new BigNumber(0);

  mounted() {
    usePresalePageFetch();

    watchEffect(() => {
      const { data: presaleData, userDataLoaded } = usePresale();
      this.presale = presaleData;
      this.userDataLoaded = userDataLoaded;

      if (this.userDataLoaded) {
        this.currentBalance = this.presale.userData.balance;
      }
    });
  }
}
